<template>
  <v-dialog
    v-model="modal"
    activator="parent"
    width="70%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center pb-0">
        Редактирование фото
      </v-card-title>
      <v-card-text v-if="photo" class="pa-0">
        <p class="modal_text">
          Выберите форму обрезки и выделите область с помощью мыши.
        </p>
        <div class="crop-type-buttons">
          <v-btn
            @click="cropType = 'square'"
            :color="cropType === 'square' ? 'primary' : ''"
          >
            Квадрат
          </v-btn>
          <v-btn
            @click="cropType = '3:4'"
            :color="cropType === '3:4' ? 'primary' : ''"
          >
            3:4
          </v-btn>
        </div>
        <div class="photo-conteiner">
          <div class="photo-conteiner_box">
            <div class="photo">
              <img ref="photo" :src="photo.fileUrl" alt="photo" />
              <canvas
                ref="canvas"
                class="canvas"
                @mousedown="handleMouseDown"
                @mousemove="handleMouseMove"
                @mouseup="handleMouseUp"
                @mouseleave="handleMouseUp"
              ></canvas>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="modal__actions">
        <v-btn color="#5d4bd0" dark @click="changeFoto()">Изменить</v-btn>
        <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import ShowMessage from "@/Functions/message";

export default {
  name: "RedactPhoto",
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    photo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selection: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
      canvas: null,
      ctx: null,
      mouseDown: false,
      isDrawing: false,
      startX: 0,
      startY: 0,
      tempSelection: null,
      cropType: 'square', // 'square' или '3:4'
    };
  },
  watch: {
    modal(val) {
      if (val) {
        setTimeout(() => {
          this.initCanvas();
        }, 200);
      }
    },
    cropType() {
      this.resetSelection();
    },
  },
  methods: {
    async changeFoto() {
      this.selection.initialWidth = this.canvas.width;
      this.selection.initialHeight = this.canvas.height;
      this.selection.id = this.photo.id;
      const response = await api.Products.redactPhoto(this.selection);
      if (response.type !== "error") {
        ShowMessage("Фото успешно обрезано", true);
        this.$emit("update:modal", false);
        this.$emit("reload");
      } else {
        ShowMessage(response.data.message);
      }
    },
    handleMouseDown(e) {
      const { offsetX, offsetY } = e;
      const inSelection = 
        offsetX >= this.selection.left &&
        offsetX <= this.selection.left + this.selection.width &&
        offsetY >= this.selection.top &&
        offsetY <= this.selection.top + this.selection.height;

      if (inSelection) {
        this.mouseDown = true;
      } else {
        this.isDrawing = true;
        this.startX = offsetX;
        this.startY = offsetY;
        this.tempSelection = {
          left: offsetX,
          top: offsetY,
          width: 0,
          height: 0,
        };
      }
    },
    handleMouseMove(e) {
      if (this.mouseDown) {
        this.moveFrame(e);
        return;
      }
      if (this.isDrawing) {
        const { offsetX, offsetY } = e;
        let width = offsetX - this.startX;
        let height = offsetY - this.startY;

        if (this.cropType === 'square') {
          const maxDelta = Math.max(Math.abs(width), Math.abs(height));
          width = width < 0 ? -maxDelta : maxDelta;
          height = height < 0 ? -maxDelta : maxDelta;
        } else if (this.cropType === '3:4') {
          const ratio = 3 / 4;
          if (Math.abs(width) / Math.abs(height) > ratio) {
            height = width / ratio;
          } else {
            width = height * ratio;
          }
          height = Math.sign(height) * Math.abs(height);
          width = Math.sign(width) * Math.abs(width);
        }

        this.tempSelection.left = Math.min(this.startX, this.startX + width);
        this.tempSelection.top = Math.min(this.startY, this.startY + height);
        this.tempSelection.width = Math.abs(width);
        this.tempSelection.height = Math.abs(height);

        this.drawRect(this.tempSelection);
      }
    },
    handleMouseUp() {
      if (this.isDrawing) {
        this.selection = {
          left: this.tempSelection.left,
          top: this.tempSelection.top,
          width: this.tempSelection.width,
          height: this.tempSelection.height,
        };
        this.checkSelection();
        this.drawRect(this.selection);
        this.isDrawing = false;
        this.tempSelection = null;
      }
      this.mouseDown = false;
    },
    moveFrame(e) {
      if (!this.mouseDown) return;
      this.selection.left = e.offsetX - this.selection.width / 2;
      this.selection.top = e.offsetY - this.selection.height / 2;
      this.checkSelection();
      this.drawRect(this.selection);
    },
    initCanvas() {
      const image = this.$refs.photo;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.style.top = `${image.offsetTop}px`;
      canvas.style.left = `${image.offsetLeft}px`;
      this.canvas = canvas;
      this.ctx = ctx;
      this.resetSelection();
    },
    resetSelection() {
      const image = this.$refs.photo;
      if (!image) return;

      if (this.cropType === 'square') {
        const size = Math.min(image.width, image.height) * 0.8;
        this.selection.width = size;
        this.selection.height = size;
        this.selection.left = (image.width - size) / 2;
        this.selection.top = (image.height - size) / 2;
      } else if (this.cropType === '3:4') {
        let width = image.width * 0.6;
        let height = (width * 4) / 3;
        if (height > image.height) {
          height = image.height * 0.8;
          width = (height * 3) / 4;
        }
        this.selection.width = width;
        this.selection.height = height;
        this.selection.left = (image.width - width) / 2;
        this.selection.top = (image.height - height) / 2;
      }
      this.checkSelection();
      this.drawRect(this.selection);
    },
    drawRect(selection) {
      const ctx = this.ctx;
      const canvas = this.canvas;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.clearRect(selection.left, selection.top, selection.width, selection.height);
      ctx.strokeStyle = "#fff";
      ctx.beginPath();
      ctx.moveTo(selection.left, 0);
      ctx.lineTo(selection.left, canvas.height);
      ctx.moveTo(selection.left + selection.width, 0);
      ctx.lineTo(selection.left + selection.width, canvas.height);
      ctx.moveTo(0, selection.top);
      ctx.lineTo(canvas.width, selection.top);
      ctx.moveTo(0, selection.top + selection.height);
      ctx.lineTo(canvas.width, selection.top + selection.height);
      ctx.stroke();
    },
    checkSelection() {
      const s = this.selection;
      const canvas = this.canvas;
      s.left = Math.max(0, Math.min(s.left, canvas.width - s.width));
      s.top = Math.max(0, Math.min(s.top, canvas.height - s.height));
      s.width = Math.max(0, Math.min(s.width, canvas.width - s.left));
      s.height = Math.max(0, Math.min(s.height, canvas.height - s.top));
    },
  },
};
</script>

<style lang="scss" scoped>
.crop-type-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
}
.photo {
  position: relative;
  cursor: move;
}
.canvas {
  position: absolute;
  cursor: crosshair;
}
.photo-conteiner_box {
  display: flex;
  justify-content: center;
}
</style>